export const environment = {
  production: true,
  mapboxToken: 'pk.eyJ1IjoiZGV2ZWxvcGVyLWZpc2hpZHkiLCJhIjoiY203bm9od2oxMDF4ZTJscHNsdG5pb2dibyJ9.XsnNO8tpSklF9s6hjsVg5g',
  baseApiUrl: 'https://preprod-chartstoreapi.fishidy.com/api',
  gisApiUrl: 'https://preprod-gisapi.fishidy.com',
  ssoBaseUrl: 'https://flirb2clab.b2clogin.com/flirb2clab.onmicrosoft.com',
  ssoClientid: '2ab6674b-f240-41a8-bf4d-37add67e4e70',
  ssoSignUpSignIn: 'B2C_1A_CORE_SignupSignIn',
  ssoResetPassword: 'B2C_1A_Core_PasswordReset',
  ssoEditProfile: 'B2C_1A_CORE_ProfileEdit',
  axiomChartManagerFlag: '1',
  axiomChartStoreFlag: '0',
  intervalInSecond: 10,
  imageUrl: 'https://preprod-chartstore.fishidy.com/static-images/',
  mapboxStylePrefix: 'mapbox://styles/',
  mapboxStyleUrl: 'developer-fishidy/ckh3m7m7t01vw19rwjmpaykfl',
  mapboxSatellite: 'mapbox/satellite-streets-v11',
  defaultFreeNAProductSKU: 'LHC-NAM-001',
  numberOfFreeChart: 1,
  lengthOfVoucherCode: 9,
  googleAnalyticsId: 'G-RM506YDYST',
  widthForMobileView: 1024,
  proportionalValue: 4,
  domainCookies: '.fishidy.com',
  exceedSizeLimitInGigabyte: 10,
  rayconnectAppleStoreLink: 'https://apps.apple.com/gb/app/rayconnect-by-raymarine/id1502278726',
  rayconnectGoogleStoreLink: 'https://play.google.com/store/apps/details?id=com.raymarine.rayconnect',
  raymarineSupportLink: 'https://teledyneflir.my.site.com/',
  mapboxApi: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
  maximumMergedCharts: 3,
  imageSizeLimit: 3145728,
  defaultBundledProductSku: 'LHC-IGB-001',
  textAreaMaxLength: 250,
  ribbonType: 4,
  mfdSoftwareUpdatesLink: 'https://www.raymarine.eu/service-and-support/software-updates/',
  worldpayGateway: "7202",
  zuoraCheckout3dsEnabledPageId: '8ad0940887756cbd0187774cd0f15c5d',
  zuoraCheckout3dsDisabledPageId: '8ad095b881d3a52a0181d53d79d83e9d',
  zuoraHostedPage: 'https://apisandbox.zuora.com/apps/PublicHostedPageLite.do',
  zuoraTermsConditions: 'https://www.zuora.com/terms-conditions/',
  monikerImageUrl: 'moniker-images/'
};
